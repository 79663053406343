<template>
  <v-container>
    <v-card>
      <v-card-title>{{ isEditMode ? 'Edit Price List' : 'Create Price List' }}</v-card-title>
      <v-form v-model="isFormValid" ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="form.name"
                label="Price List Name"
                :rules="[() => !!form.name || 'Name is required']"
              ></v-text-field>
              <v-combobox
                v-model="form.partners"
                :items="allPartners"
                item-title="name"
                item-value="id"
                label="Partners"
                multiple
                :rules="[() => !!form.partners.length || 'At least one partner is required']"
                @blur="validatePartners"
              ></v-combobox>
              <!-- TODO: date must be a valid one with the format mm/dd/yyyy -->
              <v-text-field
                v-model="form.startDate"
                label="Start Date"
                type="date"
                :rules="[() => !!form.startDate || 'Start date is required', validateDateRange]"
              ></v-text-field>
              <v-text-field
                v-model="form.endDate"
                label="End Date"
                type="date"
                :rules="[() => !!form.endDate || 'End date is required', validateDateRange]"
              ></v-text-field>
              <v-tooltip
                :disabled="!hasItemsInPriceList"
                text="You cannot import a price list with existing part numbers. Please, create a new one."
                location="top"
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props" style="width: 100%">
                    <v-file-input
                      :disabled="hasItemsInPriceList"
                      accept=".xls,.xlsx"
                      v-model="form.file"
                      prepend-icon=""
                      label="Import Price List File"
                      placeholder="Select a file or drag and drop here"
                      show-size
                      @click:clear="handleFileClear"
                    ></v-file-input>
                  </div>
                </template>
              </v-tooltip>
              <div class="flex justify-end">
                <v-btn color="primary" @click="submit" :disabled="isSaveDisabled">Save</v-btn>
                <v-btn class="ml-2" color="secondary" variant="outlined" @click="cancel">Cancel</v-btn>
              </div>
            </v-col>
            <v-col cols="9">
              <v-card>
                <v-tabs v-model="tab" bg-color="primary">
                  <v-tab value="ranges">Ranges</v-tab>
                  <v-tab value="statics">Statics</v-tab>
                  <v-tab value="series">Series</v-tab>
                </v-tabs>

                <v-card-text>
                  <v-window v-model="tab">
                    <v-window-item value="ranges">
                      <v-switch
                        v-model="expandAllToggle"
                        label="Expand All"
                        color="primary"
                        @change="toggleExpandAll"
                      ></v-switch>
                      <v-list v-if="!isPriceRangesEmpty">
                        <v-expansion-panels v-model="activePanel" multiple variant="accordion">
                          <v-expansion-panel v-for="(group, partNumber) in groupedPriceRanges" :key="partNumber">
                            <v-expansion-panel-title>
                              {{ group[0].partNumber }}
                              <v-icon
                                color="primary"
                                class="ml-1"
                                small
                                @click.stop="openEditPartNumberModal(group[0].partNumber)"
                              >
                                mdi-pencil
                              </v-icon></v-expansion-panel-title
                            >
                            <v-expansion-panel-text>
                              <div v-for="(priceRange, index) in group" :key="index">
                                <v-row>
                                  <v-col cols="2">
                                    <v-text-field
                                      v-model="priceRange.basePrice"
                                      label="Base Price"
                                      type="number"
                                      :error-messages="validationErrors[priceRange.id]"
                                      @blur="handleBlur('basePrice', index)"
                                      :rules="[isNotEmpty]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-text-field
                                      v-model="priceRange.perMeterAdder"
                                      label="Per unit adder"
                                      type="number"
                                      :error-messages="validationErrors[priceRange.id]"
                                      @blur="handleBlur('perMeterAdder', index)"
                                      :rules="[isPositiveNumber]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col>
                                    <v-text-field
                                      v-model="priceRange.minLengthInMeters"
                                      label="Min Length (included)"
                                      type="number"
                                      :error-messages="validationErrors[priceRange.id]"
                                      @blur="validatePriceRanges"
                                      :rules="[isPositiveNumber, lessThanMaxLength(priceRange)]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col>
                                    <v-text-field
                                      v-model="priceRange.maxLengthInMeters"
                                      label="Max Length (excluded)"
                                      type="number"
                                      :error-messages="validationErrors[priceRange.id]"
                                      @blur="validatePriceRanges"
                                      :rules="[isPositiveNumber, greaterThanMinLength(priceRange)]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-select
                                      v-model="priceRange.displayLengthUnit"
                                      label="Unit"
                                      :items="['M', 'F', 'CM', 'I']"
                                      @update:model-value="validatePriceRanges"
                                      :rules="[() => !!priceRange.displayLengthUnit || 'Unit is required']"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="1" class="d-flex justify-center align-center mb-4">
                                    <v-icon icon="mdi-delete" color="error" @click="deletePriceRange(priceRange)"></v-icon>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-btn
                                small
                                @click="addNewRangeToPartNumber(partNumber)"
                                color="primary"
                                variant="tonal"
                                v-if="group[0].isLengthDependent"
                              >
                                Add New Range
                              </v-btn>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list>
                      <v-alert v-else type="info" text> No price ranges created yet. </v-alert>
                      <div class="flex justify-end mt-4">
                        <v-btn small @click="openAddPriceRangeModal" color="primary" variant="tonal">Add Part Number</v-btn>
                      </div>
                    </v-window-item>

                    <v-window-item value="statics">
                      <v-list v-if="!isStaticPricesEmpty">
                        <v-list-item v-for="(staticPrice, index) in staticPrices" :key="index" color="primary">
                          <v-row>
                            <v-col cols="8">
                              <v-text-field
                                v-model="staticPrice.partNumber"
                                label="Part Number"
                                :rules="[() => !!staticPrice.partNumber || 'Part Number is required']"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                v-model="staticPrice.price"
                                label="Price"
                                type="number"
                                :rules="[isPositiveNumber]"
                                @blur="handleStaticPriceBlur(index)"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="1" class="d-flex justify-center align-center mb-4">
                              <v-icon icon="mdi-delete" color="error" @click="deleteStaticPrice(staticPrice)"></v-icon>
                            </v-col>
                          </v-row>
                        </v-list-item>
                      </v-list>
                      <v-alert v-else type="info" text> No static prices created yet. </v-alert>
                      <div class="flex justify-end mt-4">
                        <v-btn small class="ml-2" @click="openAddStaticPrice" color="primary" variant="tonal"
                          >Add Part Number</v-btn
                        >
                      </div>
                    </v-window-item>

                    <v-window-item value="series">
                      <v-list v-if="!isTrustedSeriesEmpty">
                        <v-expansion-panels v-model="activePanel" multiple variant="accordion">
                          <v-expansion-panel v-for="(group, partNumber) in groupedTrustedSeries" :key="partNumber">
                            <v-expansion-panel-title>
                              {{ group[0].partNumber }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                              <div v-for="(trustedSerie, index) in filteredTrustedSeries[partNumber]" :key="index">
                                <v-row>
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="trustedSerie.price"
                                      label="Price"
                                      type="number"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="trustedSerie.lengthInMeters"
                                      label="Length"
                                      type="number"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="trustedSerie.displayLengthUnit"
                                      label="Unit"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="3" v-if="trustedSerie.adder !== undefined">
                                    <v-text-field
                                      v-model="trustedSerie.adder"
                                      label="Adder to next in series"
                                      type="number"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list>
                      <v-alert v-else type="info" text> No trusted series created yet. </v-alert>
                    </v-window-item>
                  </v-window>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
  <v-dialog v-model="showModal" max-width="290">
    <v-card>
      <v-card-title class="headline">Enter Part Number</v-card-title>
      <v-card-text>
        <v-text-field v-model="tempPartNumber" label="Part Number"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="clearModal">Close</v-btn>
        <v-btn color="primary" text @click="addPartNumber" v-if="!isLoading"> Add </v-btn>
        <v-btn color="primary" v-if="isLoading"> <v-progress-circular indeterminate size="20"></v-progress-circular></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="staticPriceShowModal" max-width="290">
    <v-card>
      <v-card-title class="headline">Enter Part Number</v-card-title>
      <v-card-text>
        <v-text-field v-model="tempPartNumber" label="Part Number"></v-text-field>
        <v-text-field v-model="tempStaticPrice" label="Price" type="number" :rules="[isPositiveNumber]"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="clearModal">Close</v-btn>
        <v-btn color="primary" text :disabled="!tempPartNumber || !tempStaticPrice" @click="addNewStaticPrice">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showEditModal" max-width="290">
    <v-card>
      <v-card-title class="headline">Edit Part Number</v-card-title>
      <v-card-text>
        <v-text-field v-model="editTempPartNumber" label="Part Number"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="showEditModal = false">Close</v-btn>
        <v-btn color="primary" text @click="updatePartNumber" v-if="!isLoading"> OK </v-btn>
        <v-btn color="primary" v-if="isLoading"> <v-progress-circular indeterminate size="20"></v-progress-circular></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createPriceList, updatePriceList, sendFileImport } from '@/services/priceList.service';
import { getCustomers } from '@/services/customer.service';
import { getPartNumberInfo } from '@/services/parser.service';
import { dispatchToast, dispatchCopyableToast } from '@/utils/dispatchToast';
import {
  VCombobox,
  VTextField,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelTitle,
  VExpansionPanelText,
  VIcon,
} from 'vuetify/components';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    VCombobox,
    VTextField,
    VBtn,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelTitle,
    VExpansionPanelText,
    VIcon,
  },
  name: 'PriceListForm',
  props: {
    priceList: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      form: {
        name: '',
        startDate: '',
        endDate: '',
        partners: [],
        priceRanges: [],
        file: null,
      },
      staticPrices: [],
      trustedSeries: [],
      allPartners: [],
      isEditMode: false,
      showModal: false,
      staticPriceShowModal: false,
      tempPartNumber: '',
      tempStaticPrice: 0,
      activePanel: [0],
      allIndices: [],
      expandAllToggle: false,
      showEditModal: false,
      editTempPartNumber: '',
      originalPartNumber: '',
      validationErrors: {},
      isFormValid: false,
      tab: null,
      isLoading: false,
    };
  },
  computed: {
    groupedPriceRanges() {
      const partNumbers = this.groupByPartNumber(this.form.priceRanges);
      // let sortOrder = 'asc'; // or 'desc' for descending
      const extractNumberFromPartNumber = (partNumber) => {
        const match = partNumber.match(/(\d+)/); // Match the first numeric part
        return match ? parseInt(match[0], 10) : NaN; // Return numeric value or NaN
      };
      // Extract the keys (part numbers) and sort them based on the numeric part
      const sortedPartNumbers = Object.keys(partNumbers)
        .sort((a, b) => {
          const numberA = extractNumberFromPartNumber(a); // Numeric part of the first partNumber
          const numberB = extractNumberFromPartNumber(b); // Numeric part of the second partNumber
          return numberA - numberB; // Sort in ascending order
        })
        .reduce((acc, key) => {
          // Rebuild the object with sorted keys
          acc[key] = partNumbers[key];
          return acc;
        }, {});

      return sortedPartNumbers;
    },
    groupedTrustedSeries() {
      const trustedSeriesGroups = this.groupByPartNumber(this.trustedSeries);
      for (const groupKey in trustedSeriesGroups) {
        trustedSeriesGroups[groupKey].sort((seriesA, seriesB) => seriesA['lengthInMeters'] - seriesB['lengthInMeters']);
        let previousAdder = null;
        for (let seriesIndex = 0; seriesIndex < trustedSeriesGroups[groupKey].length - 1; seriesIndex++) {
          const currentSeries = trustedSeriesGroups[groupKey][seriesIndex];
          const nextSeries = trustedSeriesGroups[groupKey][seriesIndex + 1];
          const adder = this.roundStringToDecimals(
            (nextSeries.price - currentSeries.price) / (nextSeries.lengthInMeters - currentSeries.lengthInMeters),
            6,
          );

          if (adder === previousAdder) {
            currentSeries.visible = false;
          } else {
            currentSeries.adder = adder;
            currentSeries.visible = true;
            previousAdder = adder;
          }
        }
        const lastRow = trustedSeriesGroups[groupKey][trustedSeriesGroups[groupKey].length - 1];
        lastRow.adder = undefined;
        lastRow.visible = true;
      }
      return trustedSeriesGroups;
    },
    filteredTrustedSeries() {
      const grouped = this.groupedTrustedSeries;
      const filtered = {};
      for (const key in grouped) {
        filtered[key] = grouped[key].filter((trustedSerie) => trustedSerie.visible);
      }
      return filtered;
    },
    isSaveDisabled() {
      return Object.keys(this.validationErrors).length > 0 || !this.isFormValid;
    },
    hasItemsInPriceList() {
      return (
        this.form.priceRanges.length > 0 ||
        this.staticPrices.length > 0 ||
        this.trustedSeries.length > 0 ||
        (this.form.id !== undefined && this.form.id !== null && this.form.id !== '')
      );
    },
    isPriceRangesEmpty() {
      return this.form.priceRanges.length === 0;
    },
    isStaticPricesEmpty() {
      return this.staticPrices.length === 0;
    },
    isTrustedSeriesEmpty() {
      return this.trustedSeries.length === 0;
    },
  },
  methods: {
    openAddPriceRangeModal() {
      this.showModal = true;
    },
    openAddStaticPrice() {
      this.staticPriceShowModal = true;
    },
    openEditPartNumberModal(partNumber) {
      this.originalPartNumber = partNumber;
      this.editTempPartNumber = partNumber;
      this.showEditModal = true;
    },
    toggleExpandAll() {
      this.activePanel = this.expandAllToggle ? [...this.allIndices] : [];
    },
    async updatePartNumber() {
      if (!this.editTempPartNumber || this.editTempPartNumber === this.originalPartNumber) {
        this.showEditModal = false;
        return;
      }
      for (const priceRange of this.form.priceRanges) {
        if (priceRange.partNumber === this.originalPartNumber) {
          const isValid = await this.checkPartNumber(this.editTempPartNumber);
          if (!isValid) return;

          priceRange.partNumber = this.editTempPartNumber;
        }
      }
      this.showEditModal = false;
    },
    async addPartNumber() {
      if (!this.tempPartNumber || !this.validateUniquenessPartNumber(this.tempPartNumber)) {
        dispatchToast('Part number base already exists', {
          type: 'error',
        });
        return;
      }

      const isValid = await this.checkPartNumber(this.tempPartNumber);
      if (!isValid) return;

      this.addNewRangeToPartNumber(this.tempPartNumber);
      this.tempPartNumber = '';
      this.showModal = false;
      this.activePanel = 0;
    },
    async checkPartNumber(partNumber) {
      this.isLoading = true;
      const response = await getPartNumberInfo({ partNumber: partNumber });
      this.isLoading = false;
      if (!response.success) {
        dispatchToast(response.data.response.data.data.message, {
          type: 'error',
        });
        return false;
      } else if (response.data.length !== 0) {
        dispatchToast("PartNumber mustn't contain length in ranges.", {
          type: 'error',
        });
      }
      return true;
    },
    addNewRangeToPartNumber(partNumber) {
      this.form.priceRanges.push({
        id: uuidv4(),
        minLengthInMeters: '',
        maxLengthInMeters: '',
        perMeterAdder: '0',
        displayLengthUnit: '',
        basePrice: '0',
        partNumber: partNumber,
        isLengthDependent: true,
      });
    },
    addNewStaticPrice() {
      if (!this.validateStaticPrice()) {
        dispatchToast('Price must be a positive number', {
          type: 'error',
        });
        return;
      }
      if (!this.validateUniquenessPartNumber(this.tempPartNumber)) {
        dispatchToast('Part number base already exists', {
          type: 'error',
        });
        return;
      }

      this.staticPrices.push({
        id: uuidv4(),
        price: this.roundStringToDecimals(this.tempStaticPrice, 6),
        partNumber: this.tempPartNumber,
      });

      this.staticPriceShowModal = false;
      this.tempPartNumber = '';
      this.tempStaticPrice = 0;
    },
    clearModal() {
      this.staticPriceShowModal = false;
      this.showModal = false;
      this.tempPartNumber = '';
      this.tempStaticPrice = 0;
    },
    async deletePriceRange(priceRange) {
      this.form.priceRanges = this.form.priceRanges.filter((range) => range.id !== priceRange.id);
    },
    async deleteStaticPrice(staticPrice) {
      this.staticPrices = this.staticPrices.filter((range) => range.id !== staticPrice.id);
    },
    async fetchPartners() {
      try {
        const { success, data } = await getCustomers(
          { attributes: JSON.stringify(['id', 'name', 'active', 'relationshipSegment']) },
          true,
        );
        if (success) {
          this.allPartners = data;
        } else {
          throw new Error('Error fetching partners');
        }
      } catch (error) {
        dispatchToast('Error fetching partners', { type: 'error' });
      }
    },
    formatDate(dateString) {
      if (!dateString) {
        return '';
      }

      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Converts to 'YYYY-MM-DD' format
    },
    convertLength(length, unit, round = false, toMeters = true) {
      const conversionFactors = {
        F: 3.28084, // Feet to meters conversion factor
        CM: 100, // Centimeters to meters conversion factor
        I: 39.37008, // Inches to meters conversion factor
        M: 1, // Meters to meters conversion factor
      };

      const convertedLength = toMeters ? length / conversionFactors[unit] : length * conversionFactors[unit];

      return round ? parseFloat(convertedLength.toFixed(6)) : convertedLength;
    },
    roundStringToDecimals(value, decimals) {
      if (isNaN(value) || isNaN(decimals) || decimals < 0) return value;

      const factor = Math.pow(10, decimals);
      const roundedValue = Math.round(parseFloat(value) * factor) / factor;

      return parseFloat(roundedValue.toFixed(decimals)).toString();
    },
    handleBlur(field, index) {
      this.form.priceRanges[index][field] = this.roundStringToDecimals(this.form.priceRanges[index][field], 6);
      this.validatePriceRanges();
    },
    handleStaticPriceBlur(index) {
      this.staticPrices[index].price = this.roundStringToDecimals(this.staticPrices[index].price, 6);
      this.validateStaticPrice();
    },
    isNotEmpty(value) {
      return !!value || 'Value cannot be empty';
    },
    isPositiveNumber(value) {
      return (!!value && value > 0) || 'Value must be greater than 0';
    },
    lessThanMaxLength(priceRange) {
      return (value) => {
        const minLength = Number(value);
        const maxLength = priceRange.maxLengthInMeters;
        return minLength < maxLength || 'Min length must be less than max length';
      };
    },
    greaterThanMinLength(priceRange) {
      return (value) => {
        const maxLength = Number(value);
        const minLength = priceRange.minLengthInMeters;
        return maxLength > minLength || 'Max length must be greater than min length';
      };
    },
    validatePriceRanges(_priceRange) {
      this.validationErrors = {};
      this.$refs.form.validate();

      const groupedRanges = this.groupedPriceRanges;
      for (const partNumber in groupedRanges) {
        const ranges = groupedRanges[partNumber];
        for (let i = 0; i < ranges.length; i++) {
          for (let j = i + 1; j < ranges.length; j++) {
            if (this.rangesOverlap(ranges[i], ranges[j])) {
              this.validationErrors[ranges[j].id] = 'Range overlaps with another range.';
            }
          }
        }
      }
    },
    validateStaticPrice() {
      if (this.tempPartNumber && this.tempStaticPrice && this.tempStaticPrice >= 0) {
        this.validateForm();
        return true;
      }
      return false;
    },
    validateUniquenessPartNumber(partNumber) {
      return (
        !this.staticPrices.some((staticPrice) => staticPrice.partNumber === partNumber) &&
        !this.form.priceRanges.some((priceRange) => priceRange.partNumber === partNumber) &&
        !this.trustedSeries.some((trustedSerie) => trustedSerie.partNumber === partNumber)
      );
    },
    validateForm() {
      this.$refs.form.validate();
      this.isFormValid = this.$refs.form.validate();
    },
    rangesOverlap(range1, range2) {
      const range1Min = this.convertLength(range1.minLengthInMeters, range1.displayLengthUnit);
      const range1Max = this.convertLength(range1.maxLengthInMeters, range1.displayLengthUnit);
      const range2Min = this.convertLength(range2.minLengthInMeters, range2.displayLengthUnit);
      const range2Max = this.convertLength(range2.maxLengthInMeters, range2.displayLengthUnit);

      return Math.max(range1Min, range2Min) < Math.min(range1Max, range2Max);
    },
    groupByPartNumber(array) {
      this.allIndices = [];
      return array.reduce((result, item, index) => {
        if (!result[item.partNumber]) {
          result[item.partNumber] = [];
        }
        result[item.partNumber].push(item);
        this.allIndices.push(index);
        return result;
      }, {});
    },
    validateDateRange() {
      if (!this.form.startDate || !this.form.endDate) {
        return true;
      }
      return new Date(this.form.startDate) < new Date(this.form.endDate) || 'Start date must be before end date';
    },
    validatePartners() {
      const selectedPartners = this.form.partners;
      const validPartnerNames = this.allPartners.map((partner) => partner.name);

      for (const partner of selectedPartners) {
        if (!partner.name || !validPartnerNames.includes(partner.name)) {
          return `Partner "${partner}" does not exist`;
        }
      }
      return true;
    },
    handleFileClear() {
      this.form.file = undefined;
    },
    async submit() {
      const { id, partners, ...cleanedForm } = this.form;
      const partnerIds = partners.map((partner) => partner.id);
      const convertedPriceRanges = this.form.priceRanges.map((range) => ({
        ...range,
        minLengthInMeters: this.convertLength(range.minLengthInMeters, range.displayLengthUnit),
        maxLengthInMeters: this.convertLength(range.maxLengthInMeters, range.displayLengthUnit),
        perMeterAdder: this.convertLength(range.perMeterAdder, range.displayLengthUnit, false, false),
      }));

      const priceList = {
        ...cleanedForm,
        priceRanges: convertedPriceRanges,
        priceStatics: this.staticPrices,
      };

      let response;
      if (this.isEditMode) {
        response = await updatePriceList(id, priceList, partnerIds);
      } else {
        if (this.form.file) {
          let formData = new FormData();
          const partnerIds = this.form.partners.map((partner) => partner.id);

          // Define additional data
          const additionalData = {
            name: this.form.name,
            partnerIds: JSON.stringify(partnerIds),
            startDate: this.form.startDate,
            endDate: this.form.endDate,
          };

          // Append additional data to FormData
          Object.keys(additionalData).forEach((key) => {
            formData.append(key, additionalData[key]);
          });

          formData.append('file', this.form.file, this.form.file.name);
          response = await sendFileImport(formData);
        } else {
          response = await createPriceList(priceList, partnerIds);
        }
      }
      if (!response.success) {
        const errorMessages = response.data?.response?.data?.data?.message.split(';');
        if (errorMessages && errorMessages.length > 0) {
          const formattedMessages = errorMessages.map((msg) => `• ${msg.trim()}`).join('\n');

          dispatchToast(formattedMessages, {
            type: 'error',
            allowHtml: true,
            timeout: 5000,
            closeOnClick: false, // Prevent closing on text click
            pauseOnHover: false, // Ensure toast stays open on hover
            showCloseButton: true, // Ensure close button is visible
          });
        }
        dispatchToast('Price List saved successfully', { type: 'success' });
        this.$emit('submit', this.form);
      } else {
        dispatchToast('Price List saved successfully', { type: 'success' });
        // Check for warnings and details in the response
        if (response.data.warnings) {
          dispatchToast(`Warning: ${response.data.warnings}`, {
            type: 'warning',
            timeout: 5000,
            showCloseButtonOnHover: true,
          });
        }
        if (response.data.details) {
          dispatchToast(`Details: ${response.data.details}`, { type: 'info', timeout: 5000, showCloseButtonOnHover: true });
        }
        this.$emit('submit', this.form);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    priceList: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.form = {
            ...newValue,
            startDate: this.formatDate(newValue.startDate),
            endDate: this.formatDate(newValue.endDate),
            partners: newValue.partners,
            priceRanges:
              newValue.priceRanges.map((range) => ({
                ...range,
                minLengthInMeters: this.convertLength(range.minLengthInMeters, range.displayLengthUnit, true, false),
                maxLengthInMeters: this.convertLength(range.maxLengthInMeters, range.displayLengthUnit, true, false),
                perMeterAdder: this.roundStringToDecimals(
                  this.convertLength(range.perMeterAdder, range.displayLengthUnit, true, true),
                  6,
                ),
                basePrice: this.roundStringToDecimals(range.basePrice, 6),
                isLengthDependent: true,
              })) || [],
            priceStatics: newValue.priceStatics.map((staticPrice) => ({
              ...staticPrice,
              price: this.roundStringToDecimals(staticPrice.price, 6),
            })),
          };
          this.staticPrices = newValue.priceStatics || [];
          this.trustedSeries =
            newValue.trustedSeries.map((trustedSerie) => ({
              ...trustedSerie,
              lengthInMeters: this.convertLength(trustedSerie.lengthInMeters, trustedSerie.displayLengthUnit, true, false),
              adder: null,
              visible: true,
            })) || [];
          this.isEditMode = true;
        } else {
          this.isEditMode = false;
          this.form = { name: '', startDate: '', endDate: '', partners: [], priceRanges: [], priceStatics: [] };
        }
      },
    },
  },

  mounted() {
    this.form.priceRanges.sort((a, b) => a.partNumber - b.partNumber);
    this.form.priceRanges.sort((a, b) => a.minLengthInMeters - b.minLengthInMeters);
    this.fetchPartners();

    this.form.priceRanges = this.form.priceRanges.map((range) => ({
      ...range,
      perMeterAdder: this.roundStringToDecimals(range.perMeterAdder, 6),
      basePrice: this.roundStringToDecimals(range.basePrice, 6),
    }));
    this.staticPrices = this.form.priceStatics.map((staticPrice) => ({
      ...staticPrice,
      price: this.roundStringToDecimals(staticPrice.price, 6),
    }));
    this.form.priceStatics = this.staticPrices;
  },
};
</script>
